<template>
  <vx-card no-shadow>
    <div class="vx-row">
      <div class="vx-col w-full">
        <span class="name-label">组织名称：</span>
        <span>{{org.name}}</span>
      </div>
      <div class="vx-col w-full">
        <span class="name-label">当前所有人：</span>
        <span v-if="currentOwner">{{currentOwner.name}} ({{currentOwner.mobile}})</span>
        <span v-else>无</span>
      </div>
      <div class="vx-col w-full">
        <vs-input class="w-full" icon="phone" label-placeholder="教练电话" v-model="searchMobile" />
      </div>
      <!-- <div class="vx-col sm:w-1/2 w-full mb-2">
        <vs-input class="w-full" icon="phone" label-placeholder="教练电话" v-model="searchMobile" />
      </div>-->
      <!-- <div class="vx-col sm:w-1/2 w-full mb-2">
        <vs-button class="ml-auto mt-2 btnSearch" icon="search" @click="searchTrainers()">查找...</vs-button>
      </div>-->

      <!-- <vs-table :data="trainersFilterdByMobile" :maxItems="4" :maxHeight="150" :hoverFlat="true">
        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td></vs-td>
            <vs-td :data="tr.user.name" class="col0">{{tr.user.name}}</vs-td>
            <vs-td :data="tr.user.mobile">{{tr.user.mobile}}</vs-td>
          </vs-tr>
        </template>
      </vs-table>-->

      <vs-list>
        <vs-list-item
          v-for="(item, idx) in trainersFilterdByMobile"
          :key="idx"
          :title="item.user.name"
          :subtitle="item.user.mobile"
        >
          <template slot="avatar">
            <vs-avatar v-if="item.user.image_url" :src="item.user.image_url" />
            <vs-avatar v-else :text="item.user.name" />
          </template>
          <vs-button color="danger" @click="changeOwner(item.trainer)">设置为组织所有人</vs-button>
        </vs-list-item>
      </vs-list>
    </div>

    <!-- Save & Reset Button
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-auto mt-2" @click="saveChange()">保存修改</vs-button>
    </div>-->
  </vx-card>
</template>

<script>
// import VxAutoSuggest from "@/layouts/components/vx-auto-suggest/VxAutoSuggest.vue";

import baseFun from "@/views/components/coach/baseFunctions.js";

import { mapState } from "vuex";

export default {
  mixins: [baseFun],

  props: ["organization"],

  data() {
    return {
      org: this.getNewOrganization(),
      searchMobile: "",
      confirmedMobile: "",
      itemsLimit: 3
    };
  },

  computed: {
    currentOwner() {
      let owner_id = this.org && this.org.owner_id ? this.org.owner_id : null;
      let trainer =
        owner_id && this.trainers
          ? this.trainers.find(item => item.id == owner_id)
          : null;
      let user =
        trainer && this.users
          ? this.users.find(item => item.id == trainer.user_id)
          : null;
      return user;
    },

    trainersFilterdByMobile() {
      let mobileNum = this.searchMobile;
      let aryRtn = [];
      let itemsLimit = this.itemsLimit;
      let currentOwnerMobile = this.currentOwner
        ? this.currentOwner.mobile
        : null;
      if (mobileNum)
        this.trainers.forEach(item => {
          if (item.is_organization_owner || aryRtn.length >= itemsLimit) return;

          let user = this.users.find(userItem => userItem.id == item.user_id);
          if (
            user.mobile.indexOf(mobileNum) >= 0 &&
            currentOwnerMobile != user.mobile
          )
            aryRtn.push({ trainer: item, user: user });
        });
      return aryRtn;
    },

    ...mapState(["organizations", "trainers", "clients", "users"])
  },
  created() {
    if (this.organization)
      this.org = JSON.parse(JSON.stringify(this.organization));

    this.$store.dispatch("getTrainers");
  },
  watch: {
    organization(newVal) {
      this.org = newVal
        ? JSON.parse(JSON.stringify(newVal))
        : this.getNewOrganization();
      this.searchMobile = this.confirmedMobile = "";
    }
  },

  methods: {
    searchTrainers() {
      this.$store.dispatch("getTrainers", {
        critical: { mobile: this.searchMobile },
        vm: this,
        callback: this.searchCallback
      });
    },

    searchCallback() {
      this.confirmedMobile = this.searchMobile;
    },

    changeOwner(newOwner) {
      //原所有人取消所有人身份
      let owner_id = this.org && this.org.owner_id ? this.org.owner_id : null;
      let trainer =
        owner_id && this.trainers
          ? this.trainers.find(item => item.id == owner_id)
          : null;
      let oldOwner = trainer ? JSON.parse(JSON.stringify(trainer)) : null;
      if (oldOwner) {
        oldOwner.is_organization_owner = false;
        this.$store.dispatch("updateTrainer", {
          vm: this,
          trainer: oldOwner
        });
      }

      // 设置组织新的所有人
      if (newOwner) {
        this.org.owner_id = newOwner.id;
        this.$store.dispatch("updateOrganization", {
          organization: this.org,
          vm: this
        });
      }

      //设置新所有人所属组织
      let owner = JSON.parse(JSON.stringify(newOwner));
      owner.organization_id = this.org.id;
      this.$store.dispatch("updateTrainer", {
        vm: this,
        trainer: owner
      });
    }
  },

  components: {
    // VxAutoSuggest
  }
};
</script>

<style scoped>
.name-label {
  font-weight: bold;
}
</style>
