<template>
  <vx-card no-shadow>
    <vs-input class="w-full mb-base" label-placeholder="组织名称" v-model="org.name"></vs-input>

    <!-- Img Row -->
    <div class="flex flex-wrap items-center mb-base">
      <vs-avatar v-if="org.image_url" :src="org.image_url" size="70px" class="mr-4 mb-4" />
      <vs-avatar v-else text="LOGO" size="70px" class="mr-4 mb-4" />
      <div>
        <vs-button class="mr-4 sm:mb-0 mb-2" @click="$refs.files.click()">上传图片</vs-button>
        <p class="text-sm mt-2">只允许上传JPG, GIF 或者 PNG. 最大尺寸不超过800kB</p>

        <!--file upload-->
        <input
          type="file"
          id="files"
          ref="files"
          @change="fileSelected()"
          style="visibility:hidden"
        />
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-auto mt-2" @click="saveChange()">保存修改</vs-button>
      <!-- <vs-button v-if="org.id>0" class="ml-4 mt-2" color="danger" @click="deleteOrg">删除</vs-button> -->
    </div>
  </vx-card>
</template>

<script>
import axios from "axios";
import baseFun from "@/views/components/coach/baseFunctions.js";

import { mapState } from "vuex";

export default {
  mixins: [baseFun],

  props: ["organization"],

  data() {
    return {
      org: this.getNewOrganization()
    };
  },
  computed: {
    ...mapState(["organizations", "trainers", "clients", "users"])
  },
  created() {
    if (this.organization)
      this.org = JSON.parse(JSON.stringify(this.organization));
  },
  watch: {
    organization(newVal) {
      this.org = newVal
        ? JSON.parse(JSON.stringify(newVal))
        : this.getNewOrganization();
    }
  },

  methods: {
    fileSelected() {
      let self = this;
      let file = this.$refs.files.files[0];

      let formData = new FormData();
      formData.append("file", file);

      let uploadPath = "";
      if (file.type.indexOf("image/") == 0) uploadPath = "/upload/image";
      if (file.type.indexOf("video/") == 0) uploadPath = "/upload/file";
      if (!uploadPath) {
        this.$vs.notify({
          title: "错误",
          text: "文件类型不支持",
          color: "danger",
          position: "top-right"
        });
        this.$refs.files.value = "";
        return;
      }

      axios
        .post(uploadPath, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function(resp) {
          // console.log(resp.data)
          self.org.image_url = resp.data.url;
          self.$refs.files.value = "";

          // console.log("SUCCESS!!");
        })
        .catch(function(err) {
          // console.log("FAILURE!!" + err);
          self.$vs.notify({
            title: "错误",
            text: err,
            color: "danger",
            position: "top-right"
          });
          self.$refs.files.value = "";
        });
    },

    saveChange() {
      if (this.org.name) {
        let action = this.org.id ? "updateOrganization" : "createOrganization";
        this.$store.dispatch(action, {
          organization: this.org,
          vm: this
        });
        this.$emit("hide");
      }
    },

    deleteOrg() {
      if (this.org.id) {
        this.$store.dispatch("deleteOrganization", {
          organization: this.org,
          vm: this
        });
      }
      this.$emit("hide");
    }
  }
};
</script>
