<template>
  <div>
    <vs-row>
      <vs-col vs-w="10" vs-type="flex" vs-justify="flex-end">
        <vs-button color="primary" @click="addOrganization">创建组织</vs-button>
      </vs-col>
      <vs-col vs-w="2" vs-type="flex" vs-justify="flex-end">
        <vs-button color="primary" @click="refresh">刷新</vs-button>
      </vs-col>
    </vs-row>

    <vs-table search :data="organizations" max-items="10" pagination>
      <template slot="header">
        <h3>组织</h3>
      </template>
      <template slot="thead">
        <vs-th>徽标</vs-th>
        <vs-th>名称</vs-th>
        <vs-th>学员数量</vs-th>
        <vs-th>编辑</vs-th>
        <vs-th>所有人</vs-th>
        <vs-th>删除</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <vs-avatar v-if="tr.image_url" class="m-0" :src="tr.image_url" size="35px" />
            <vs-avatar v-else class="m-0" :text="tr.name" size="35px" />
          </vs-td>
          <vs-td class="field0">{{tr.name}}</vs-td>
          <vs-td :data="tr.active_clients_count">{{tr.active_clients_count}}</vs-td>
          <vs-td>
            <a href="javascript:null" @click="editOrganization(tr)">编辑</a>
          </vs-td>
          <vs-td>
            <a href="javascript:null" v-if="tr.owner_id" @click="setOwner(tr)">修改组织所有人</a>
            <a href="javascript:null" v-else @click="setOwner(tr)">设置组织所有人</a>
          </vs-td>
          <vs-td>
            <vs-button color="danger" siza="small" @click="deleteOrg(tr)">删除</vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!-- Organization Edit -->
    <vs-popup class="holamundo" :title="selectedOrganization?'组织信息修改':'新建组织'" :active.sync="showOrganizationEdit">
      <OrganizationEdit
        :organization="selectedOrganization"
        @hide="showOrganizationEdit=false"
      />
    </vs-popup>

    <!-- Organization Owner -->
    <vs-popup class="holamundo" title="设置组织所有人" :active.sync="showOrganizationOwner">
      <OrganizationOwner
        :organization="selectedOrganization"
        @hide="showOrganizationOwner=false"
      />
    </vs-popup>
  </div>
</template>

<script>
// import NewClient from "@/views/components/coach/client/NewClient.vue";
// import NewTrainer from "@/views/components/coach/trainer/NewTrainer.vue";
import OrganizationEdit from "@/views/components/coach/organization/OrganizationEdit.vue";
import OrganizationOwner from "@/views/components/coach/organization/OrganizationOwner.vue";

import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],

  data() {
    return {
      showOrganizationEdit: false,
      selectedOrganization: null,

      showOrganizationOwner: false
    };
  },

  created() {
    this.$store.commit("adminPageIdx", 0);
    this.$store.dispatch("getOrganizations", {});
  },

  computed: {
    ...mapState([
      "organizations",
      "activeOrganization",
      "users",
      "trainers",
      "clients"
    ])
  },

  methods: {
    refresh() {
      this.$store.dispatch("getOrganizations", { vm: this });
    },

    addOrganization() {
      this.selectedOrganization = null;
      this.showOrganizationEdit = true;
    },

    editOrganization(org) {
      this.selectedOrganization = org;
      this.showOrganizationEdit = true;
    },

    setOwner(org){
      this.selectedOrganization = org;
      this.showOrganizationOwner = true;
    },

    deleteOrg(org) {
      this.selectedOrganization = org;
      this.$vs.dialog({
        type: 'confirm',
        color:'danger',
        title: `警告`,
        text: ' 删除动作不可恢复，是否删除"' + org.name + '"？ ',
        "accept-text": '确定',
        "cancel-text": '取消',
        accept:this.deleteOrgProcess
      })
    },

    deleteOrgProcess(){
      if (this.selectedOrganization) {
        this.$store.dispatch("deleteOrganization", {
          organization: this.selectedOrganization,
          vm: this
        });
      }
    }
  },

  components: {
    OrganizationEdit,
    OrganizationOwner
  }
};
</script>

<style scoped>
.field0 {
  white-space: nowrap;
}
</style>
